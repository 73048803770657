import { User as AuthUser } from "@auth0/auth0-react";
import { observable, action } from "mobx";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import { User, UserSettings } from "../models/User";
import {
  checkUnpaidInvoices,
  createCompanyUser,
  createStripeProfile,
  createUser,
  getAuth0User,
  getInvitedUser,
  getStripeProfile,
  getSubscription,
  getUser,
  updateCompanyUserInvite,
  updateUser,
} from "../../helpers/api";
import { FeatureType, CompanyUserStatus } from "../../helpers/Enums";
import { CompanyUserInvite } from "../models/CompanyUserInvite";
import { eventEmitter } from "../../helpers/EventEmiitter";

import RootStore from "./RootStore";
import stores from ".";

const defaultSettings: UserSettings = {
  is_notifications_active: true,
  is_fine_tuned_avatar_chat_active: false,
  is_fine_tuned_general_chat_active: false,
  is_auto_sync_active: false,
  auto_sync_time: "",
};

type UserLimitations = {
  maxCompanyUser: number;
  maxCompanyApps: number;
  maxCompanyModels: number;
  maxCompanyStorages: number;
};

export default class UserStore {
  @observable currentUser!: User;
  @observable currentUserInvitation?: CompanyUserInvite;
  @observable isCurrentUserReady: boolean = false;
  @observable isCurrentUserAdmin?: boolean;
  @observable isFreeUser: boolean = false;
  @observable hasUnpaidInvoices: boolean = false;
  @observable limitations: UserLimitations | null = null;

  private isUserSetted: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(rootStore: RootStore) {}

  @action setUser = async (user: AuthUser | undefined) => {
    if (this.isUserSetted) return;

    this.isUserSetted = true;

    await this.setAccessToken();

    if (user && user.sub) {
      const existingUser = await getAuth0User(user.sub);

      if (existingUser) {
        await this.refreshUserData(user, existingUser);
      } else {
        const newUser = await createUser(
          user.name,
          user.sub,
          user.picture,
          user.email,
          user.email_verified !== undefined ? user.email_verified : false,
          user.gender,
          user.locale,
          user.phone_number,
          user.phone_number_verified !== undefined
            ? user.phone_number_verified
            : false,
          defaultSettings,
          false
        );

        if (newUser) {
          this.currentUser = newUser;
        }
      }

      if (this.isFreeUser) {
        this.applyFreeUserLimits();
      }
    }

    if (this.currentUser.email)
      await this.getStripeUser(this.currentUser.email);

    await this.checkUserInvitation();

    await this.checkUserSubscription();

    if (this.isFreeUser) {
      this.applyFreeUserLimits();
    }

    this.isCurrentUserReady = true;
  };

  @action updateUserData = async (): Promise<boolean> => {
    const result = await updateUser(this.currentUser);
    return result;
  };

  @action getStripeUser = async (email: string): Promise<boolean> => {
    const stripeProfile = await getStripeProfile(email);

    if (!stripeProfile) {
      const newStripeProfile = await createStripeProfile(email);
      this.currentUser.stripeProfile = newStripeProfile;
    } else {
      this.currentUser.stripeProfile = stripeProfile;
    }

    if (this.currentUser.email) {
      this.hasUnpaidInvoices = (
        await checkUnpaidInvoices(this.currentUser.email)
      ).hasUnpaidInvoices;
    }

    if (this.currentUser.stripeProfile) {
      getSubscription(this.currentUser.stripeProfile.id).then(
        (subscription) => {
          if (subscription) {
            this.currentUser.subscription = subscription;
          }
        }
      );
    }

    return true;
  };

  @action checkUserInvitation = async (): Promise<boolean> => {
    if (this.currentUser.email) {
      const companyUserInvite = await getInvitedUser(this.currentUser.email);

      if (!companyUserInvite) return false;

      const adminUser = await getUser(companyUserInvite.company.user_id);
      if (adminUser && adminUser.email)
        await this.getStripeUser(adminUser.email);
      else return false;

      if (companyUserInvite.status === CompanyUserStatus.InProgress) {
        const newCompanyUserInvite = {
          ...companyUserInvite,
          status: CompanyUserStatus.Approved,
        };
        await updateCompanyUserInvite(newCompanyUserInvite);
        await createCompanyUser(
          this.currentUser.id,
          newCompanyUserInvite.company_id,
          newCompanyUserInvite.role_id,
          newCompanyUserInvite.status
        );

        this.currentUserInvitation = newCompanyUserInvite;

        return true;
      } else if (companyUserInvite.status === CompanyUserStatus.Approved) {
        this.currentUserInvitation = companyUserInvite;
        return true;
      }

      return false;
    }

    return false;
  };

  @action checkUserSubscription = async (): Promise<boolean> => {
    if (!this.currentUser.stripeProfile) {
      this.isFreeUser = true;
      return false;
    } else {
      const subscription = await getSubscription(
        this.currentUser.stripeProfile.id
      );

      if (!subscription) {
        this.isFreeUser = true;
        return false;
      } else {
        this.currentUser.subscription = subscription;
        this.isFreeUser = false;
        return true;
      }
    }
  };

  @action checkSubscribedFeatureType = async (
    featureType: FeatureType
  ): Promise<boolean> => {
    const hasSubscription = await this.checkUserSubscription();

    switch (featureType) {
      case FeatureType.CreateAvatar:
        if (hasSubscription) return true;
        else return false;
      case FeatureType.ConnectApp:
        if (hasSubscription) {
          return true;
        } else if (
          this.limitations &&
          stores.companyAppStore.companyApps.length <
            this.limitations.maxCompanyApps
        ) {
          return true;
        } else {
          return false;
        }
      case FeatureType.ConnectModel:
        if (hasSubscription) {
          return true;
        } else if (
          this.limitations &&
          stores.companyModelStore.companyModels.length <
            this.limitations.maxCompanyModels
        ) {
          return true;
        } else return false;
      case FeatureType.CompanyStorage:
        if (hasSubscription) {
          return true;
        } else if (
          this.limitations &&
          stores.companyStorageStore.companyStorages.length <
            this.limitations.maxCompanyStorages
        ) {
          return true;
        } else return false;
      case FeatureType.CompanyUser:
        const acceptedUsersCount = stores.companyUserStore.companyUsers.filter(
          (user) => user.status === CompanyUserStatus.Approved
        ).length;

        if (hasSubscription) {
          return true;
        } else if (
          this.limitations &&
          acceptedUsersCount < this.limitations.maxCompanyUser
        ) {
          return true;
        } else {
          return false;
        }
      case FeatureType.TheChief:
        if (hasSubscription && this.isCurrentUserAdmin) {
          return true;
        } else {
          return false;
        }
      case FeatureType.VoiceAsk:
        if (hasSubscription) {
          return true;
        } else {
          return false;
        }
      case FeatureType.LiveAvatar:
        const isLiveAvatarAvailable =
          stores.companyStore.selectedUserCompany?.is_live_avatar_available;

        if (hasSubscription && isLiveAvatarAvailable) {
          return true;
        } else {
          console.error("LiveAvatar Check Failed: ", {
            hasSubscription,
            isLiveAvatarAvailable,
          });
          return false;
        }
      case FeatureType.AutoSync:
        if (hasSubscription) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };

  @action applyFreeUserLimits = () => {
    this.limitations = {
      maxCompanyUser: 3,
      maxCompanyApps: 3,
      maxCompanyModels: 1,
      maxCompanyStorages: 1,
    };
  };

  @action logout = () => {
    Cookies.remove("idToken", {
      path: "/",
      domain: process.env.REACT_APP_DOMAIN,
    });

    setTimeout(() => {
      eventEmitter.emit("logout");
    }, 2000);
  };

  private setAccessToken = async (): Promise<boolean> => {
    if (process.env.REACT_APP_IS_DEV === "false") {
      try {
        const { getIdTokenClaims } = useAuth0();
        const idToken = await getIdTokenClaims();

        Cookies.remove("idToken", {
          path: "/",
          domain: process.env.REACT_APP_DOMAIN,
        });

        Cookies.set("idToken", idToken ? idToken.__raw : "", {
          expires: process.env.REACT_APP_ID_TOKEN_EXPIRATION
            ? Number(process.env.REACT_APP_ID_TOKEN_EXPIRATION)
            : 36000,
          secure: true,
          domain: process.env.REACT_APP_DOMAIN,
          sameSite: "None",
        });

        return true;
      } catch (error) {
        throw error;
      }
    } else {
      return true;
    }
  };

  private refreshUserData = async (
    user: AuthUser,
    currentUser: User
  ): Promise<boolean> => {
    const updatedUser: User = {
      id: currentUser.id,
      auth0_id: currentUser.auth0_id,
      name: currentUser.name,
      picture: currentUser.picture,
      email: user.email,
      email_verified:
        user.email_verified !== undefined ? user.email_verified : false,
      gender: user.gender,
      locale: currentUser.locale,
      phone_number: currentUser.phone_number,
      phone_number_verified:
        user.phone_number_verified !== undefined
          ? user.phone_number_verified
          : false,
      settings: currentUser.settings,
      is_onboarded: currentUser.is_onboarded,
      created_at: currentUser.created_at,
    };

    await updateUser(updatedUser);

    this.currentUser = updatedUser;

    return true;
  };
}
